<template>
  <div class="ExamNewList">
    <div class="exam-header">
      <div class="exam-left-box">
        <div>
          学段：
          <el-select
            v-model="searchItems.level"
            class="secrch-item"
            :collapse-tags="true"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="全部学段"
            @change="changeLevel"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          入学年份：
          <el-select
            v-model="searchItems.year"
            class="secrch-item"
            :collapse-tags="true"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="入学年份"
            @change="changeGrade"
          >
            <template>
              <el-option
                v-for="item in yearList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </template>
          </el-select>
        </div>
        <div>
          考试模式：
          <el-select
            v-model="searchItems.mode"
            class="secrch-item"
            clearable
            filterable
            placeholder="考试模式"
            @change="changeMode"
          >
            <template>
              <el-option label="普通考试" :value="0"> </el-option>
              <el-option label="新高考" :value="1"> </el-option>
            </template>
          </el-select>
        </div>
        <div>
          考试类型：
          <el-select
            v-model="searchItems.type"
            class="secrch-item"
            :collapse-tags="true"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="全部类型"
            @change="handleClick()"
          >
            <!-- <el-option label="全部" value=""> </el-option> -->
            <el-option
              v-for="item in examTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          学科：
          <el-select
            v-model="searchItems.subject"
            :collapse-tags="true"
            class="secrch-item"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="全部学科"
            @change="handleClick()"
          >
            <el-option
              v-for="item in examsubjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          班级：
          <el-select
            v-model="searchItems.classes"
            class="secrch-item"
            :collapse-tags="true"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="全部班级"
            @change="handleClick()"
          >
            <el-option
              v-for="item in classOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div v-if="$route.name != '/exam/unionExam'">
          发布状态：
          <el-select
            v-model="searchItems.dataRelease"
            :collapse-tags="true"
            class="secrch-item"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="全部发布状态"
            @change="handleClick()"
          >
            <el-option
              v-for="item in reportList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <el-input
          v-model="searchItems.input"
          class="search-input"
          placeholder="请输入搜索内容"
          @keyup.enter.native="handleClick"
        >
          <i
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="handleClick"
          >
          </i
        ></el-input>
      </div>
      <!-- {{ $route.name }} -->
      <div class="add-exam-btn">
        <el-button
          v-if="userInfo.roleId == 1"
          class=""
          type="primary"
          plain
          @click="copyList()"
        >
          复制考试
        </el-button>
        <el-button
          v-if="
            userInfo.roleId == 1 ||
            userInfo.roleId == 2 ||
            userInfo.roleId == 3 ||
            userInfo.roleId == 28
          "
          type="primary"
          plain
          @click="editList(1, 1)"
          >新建考试</el-button
        >
      </div>
    </div>
    <div
      :key="listKey"
      v-loading="dataListLoading"
      class="exam-question-list-box"
    >
      <no-data v-if="dataList.length == 0"></no-data>
      <template v-else>
        <!-- <template > -->
        <ExamItem
          v-for="(v, i) in dataList"
          :key="i"
          :ref="'ExamItem' + v.id"
          :item-data="v"
          :report-status-list="reportStatusList"
          @editList="editList"
          @setList="setList"
          @onloadGetList="onloadGetList"
        ></ExamItem>
        <!-- </template> -->
        <el-pagination
          v-if="total > 1"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </template>
      <!-- {{ dataList }} -->
    </div>

    <!-- 修改考试 新建 弹出层 -->
    <el-dialog
      :title="ruleForm.id ? '修改考试' : '新建考试'"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <div>
        <el-tabs
          :key="formKeys"
          v-model="activeName"
          type="border-card"
          @tab-click="typeClick"
        >
          <el-tab-pane
            v-if="!ruleForm.id || (ruleForm.id && !ruleForm.ruleId)"
            label="普通考试"
            name="exam"
          >
            <el-form
              v-if="activeName == 'exam'"
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="100px"
              class="demo-ruleForm"
            >
              <div class="form-item-box">
                <el-form-item
                  :label="userInfo.roleId != 6 ? '考试名称' : '联考名称'"
                  prop="name"
                >
                  <el-input
                    v-model="ruleForm.name"
                    maxlength="40"
                    show-word-limit
                    class="text-nums"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item-box two-box">
                <el-form-item
                  :label="userInfo.roleId != 6 ? '学段' : '学段筛选'"
                  prop="level"
                >
                  <!--  -->
                  <el-select
                    v-model="ruleForm.level"
                    placeholder="请选择学段"
                    :disabled="getDis()"
                    @change="changeGradeOptions(true)"
                  >
                    <el-option
                      v-for="item in levelOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="入学年份" prop="year">
                  <el-select
                    v-model="ruleForm.year"
                    class="secrch-item"
                    placeholder="入学年份"
                    :disabled="getDis()"
                    @change="changeClassOptions(true)"
                  >
                    <!-- <template> -->
                    <el-option
                      v-for="item in yearList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                    <!-- </template> -->
                  </el-select>
                  <!-- <el-date-picker
                    v-model="ruleForm.year"
                    type="year"
                    placeholder="选择年"
                    format="yyyy"
                    value-format="yyyy"
                    @change="changeClassOptions(true)"
                  >
                  </el-date-picker> -->
                  <!-- <el-select
                    v-model="ruleForm.year"
                    placeholder="请选择入学年份"
                    @change="changeClassOptions(true)"
                  >
                    <el-option
                      v-for="item in schooleYearOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </div>
              <div class="form-item-box two-box">
                <el-form-item
                  :label="userInfo.roleId != 6 ? '考试日期' : '联考日期'"
                  prop="examDate"
                >
                  <el-date-picker
                    v-model="ruleForm.examDate"
                    type="date"
                    placeholder="请选择考试日期"
                    format="yyyy 年 M 月 d 日"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  v-if="userInfo.roleId != 6"
                  label="考试类型"
                  prop="type"
                >
                  <el-select
                    v-model="ruleForm.type"
                    placeholder="请选择考试类型"
                  >
                    <el-option
                      v-for="item in examTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div v-if="userInfo.roleId != 6" class="form-item-box two-box">
                <el-form-item label="考试年级" prop="gradeId">
                  <el-select
                    v-model="ruleForm.gradeId"
                    placeholder="请选择考试年级"
                    clearable
                  >
                    <el-option
                      v-for="item in gradeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="item-box-btn">
                  <template v-if="!ruleForm.id">
                    <p @click="examClassIdListTo('1')">全选</p>
                    |
                    <p @click="examClassIdListTo('2')">反选</p>
                    |
                    <p @click="examClassIdListTo('3')">取消</p>
                  </template>
                </div>
                <div class="checkbox-item">
                  <!-- <el-form-item label="" prop="examClassIdList" style="width:100%"> -->
                  <el-form-item label="" style="width: 100%">
                    <el-checkbox-group
                      v-if="classOptionsModel.length > 0"
                      v-model="ruleForm.examClassIdList"
                    >
                      <el-checkbox
                        v-for="item in classOptionsModel"
                        :key="item.id"
                        :disabled="ruleForm.id > 0"
                        :label="item.id + ''"
                      >
                        {{ item.classNum }}
                      </el-checkbox>
                    </el-checkbox-group>
                    <div v-if="classOptionsModel.length == 0">
                      暂无可选班级，请确保学段和入学年份选择正确
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item-box">
                <el-form-item label="考试科目" prop="examSubjectIdList">
                  <el-checkbox-group
                    v-if="ruleForm.level"
                    v-model="ruleForm.examSubjectIdList"
                  >
                    <el-checkbox
                      v-for="(item, index) in subjectOptions"
                      :key="index"
                      :label="item.value"
                      >{{ item.label }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div class="form-item-box">
                <el-form-item
                  v-if="userInfo.roleId == 6"
                  label="参考学校"
                  prop="schoolIdList"
                >
                  <el-button type="primary" plain @click="addSchool"
                    >新增 <i class="el-icon-plus el-icon--right"></i
                  ></el-button>
                  <div class="tags">
                    <el-tag
                      v-for="(item, index) in ruleForm.schoolIdList"
                      :key="index"
                      closable
                      @close="closeTag(index)"
                      >{{ getSchoolName(item) }}</el-tag
                    >
                  </div>
                </el-form-item>
              </div>
              <div v-if="userInfo.roleId == 6" class="form-item-box">
                <el-form-item label="学校管理：" prop="examAllow">
                  <el-radio-group v-model="ruleForm.examAllow">
                    <el-radio :label="1">允许</el-radio>
                    <el-radio :label="0">不允许</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="form-item-box">
                <el-form-item label="保密级别" prop="securityLevel">
                  <el-radio-group
                    v-model="ruleForm.securityLevel"
                    @change="setSecur"
                    ><el-radio
                      v-for="(item, index) in examSecurityLevelOptions"
                      :key="index"
                      style="margin-bottom: 0"
                      :label="item.value"
                      >{{ item.label }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="form-item-box">
                <el-form-item label="数据发布" prop="dataReleaseList">
                  <el-checkbox-group v-if="ruleForm.securityLevel == 2">
                    <el-checkbox
                      v-for="(item, index) in examReportAutoSendOptions"
                      :key="index"
                      :label="item.value"
                      disabled
                      >{{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <el-checkbox-group v-else v-model="ruleForm.dataReleaseList">
                    <el-checkbox
                      v-for="(item, index) in examReportAutoSendOptions"
                      :key="index"
                      :label="item.value"
                      >{{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </el-form>
          </el-tab-pane>
          <!--       -->
          <el-tab-pane v-if="getshowNewExam()" label="新高考" name="newExam">
            <el-form
              v-if="activeName == 'newExam'"
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="100px"
              class="demo-ruleForm"
            >
              <div class="form-item-box">
                <el-form-item
                  :label="userInfo.roleId != 6 ? '考试名称' : '联考名称'"
                  prop="name"
                >
                  <el-input
                    v-model="ruleForm.name"
                    maxlength="40"
                    show-word-limit
                    class="text-nums"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item-box two-box">
                <el-form-item
                  :label="userInfo.roleId != 6 ? '学段' : '学段筛选'"
                  prop="level"
                >
                  <el-select
                    v-model="ruleForm.level"
                    :disabled="true"
                    placeholder="请选择学段"
                    @change="changeGradeOptions(true)"
                  >
                    <el-option label="高中" value="3"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="入学年份" prop="year">
                  <el-select
                    v-model="ruleForm.year"
                    class="secrch-item"
                    placeholder="入学年份"
                    :disabled="getDis()"
                    @change="changeClassOptions(true)"
                  >
                    <el-option
                      v-for="item in yearList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-date-picker
                    v-model="ruleForm.year"
                    type="year"
                    placeholder="选择年"
                    format="yyyy"
                    value-format="yyyy"
                    @change="changeClassOptions(true)"
                  >
                  </el-date-picker> -->
                  <!-- <el-select
                    v-model="ruleForm.year"
                    placeholder="请选择入学年份"
                    @change="changeClassOptions(true)"
                  >
                    <el-option
                      v-for="item in schooleYearOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->
                </el-form-item>
              </div>
              <div class="form-item-box two-box">
                <el-form-item
                  :label="userInfo.roleId != 6 ? '考试日期' : '联考日期'"
                  prop="examDate"
                >
                  <el-date-picker
                    v-model="ruleForm.examDate"
                    type="date"
                    placeholder="请选择考试日期"
                    format="yyyy 年 M 月 d 日"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  v-if="userInfo.roleId != 6"
                  label="考试类型"
                  prop="type"
                >
                  <el-select
                    v-model="ruleForm.type"
                    placeholder="请选择考试类型"
                  >
                    <el-option
                      v-for="item in examTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!--   <div v-if="userInfo.roleId != 6 && $route.name != '/exam/unionExam'"> -->
              <div v-if="userInfo.roleId != 6" class="form-item-box two-box">
                <el-form-item label="考试年级" prop="gradeId">
                  <el-select
                    v-model="ruleForm.gradeId"
                    placeholder="请选择考试年级"
                    clearable
                  >
                    <el-option
                      v-for="item in gradeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="form-item-box two-box"
                style="justify-content: flex-start"
              >
                <el-form-item label="新高考规则" prop="ruleId">
                  <el-select
                    v-model="ruleForm.ruleId"
                    placeholder="请选择新高考规则"
                  >
                    <el-option
                      v-for="item in examnewruleListData"
                      :key="item.id"
                      :label="item.examModelDescribe"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div v-if="indexRuleData && indexRuleData.examModelDescribe">
                  <!-- hover -->
                  <el-popover
                    placement="right"
                    width="510"
                    trigger="hover"
                    popper-class="popper-box"
                  >
                    <div class="rule-box">
                      <!-- <div>{{ indexRuleData.examModelDescribe }}新高考规则</div> -->
                      <div>选考模式：{{ indexRuleData.examModelDescribe }}</div>
                      <div>
                        {{
                          indexRuleData.secondSubject ? "首选科目" : "选考科目"
                        }}：{{ indexRuleData.firstSubject }}
                      </div>
                      <div v-if="indexRuleData.secondSubject">
                        再选科目：{{ indexRuleData.secondSubject }}
                      </div>
                      <div class="role-list-box">
                        <p>赋分规则：</p>
                        <div class="role-list">
                          <div>等级名称</div>
                          <div>人数比例</div>
                          <div>
                            <template
                              v-if="
                                indexRuleData.ruleRankList.length > 0 &&
                                indexRuleData.ruleRankList[0].rankScore.split(
                                  '~'
                                )[1]
                              "
                            >
                              赋分区间
                            </template>
                            <template v-else> 赋分分值 </template>
                          </div>
                        </div>
                        <div class="role-item">
                          <div
                            v-for="(v, i) in indexRuleData.ruleRankList"
                            :key="i"
                            class="role-list"
                          >
                            <div>{{ v.rankName }}</div>
                            <div>{{ v.rankNum }}</div>
                            <div>{{ v.rankScore }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div slot="reference" class="role-btn">
                      {{ indexRuleData.examModelDescribe }}
                      <i class="el-icon-warning"></i>
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="form-item-box">
                <el-form-item label="考试科目" prop="examSubjectIdList">
                  <el-checkbox-group v-model="ruleForm.examSubjectIdList">
                    <el-checkbox
                      v-for="(item, index) in subjectOptions"
                      :key="index"
                      :label="item.value"
                      >{{ item.label }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div class="form-item-box">
                <el-form-item
                  v-if="userInfo.roleId == 6"
                  label="参考学校"
                  prop="schoolIdList"
                >
                  <el-button type="primary" plain @click="addSchool"
                    >新增 <i class="el-icon-plus el-icon--right"></i
                  ></el-button>
                  <div class="tags">
                    <el-tag
                      v-for="(item, index) in ruleForm.schoolIdList"
                      :key="index"
                      closable
                      @close="closeTag(index)"
                      >{{ getSchoolName(item) }}</el-tag
                    >
                  </div>
                </el-form-item>
              </div>
              <div v-if="userInfo.roleId == 6" class="form-item-box">
                <el-form-item label="学校管理：" prop="schoolAuth">
                  <el-radio-group v-model="ruleForm.schoolAuth">
                    <el-radio :label="1">允许</el-radio>
                    <el-radio :label="0">不允许</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="form-item-box">
                <el-form-item label="保密级别" prop="securityLevel">
                  <el-radio-group
                    v-model="ruleForm.securityLevel"
                    @change="setSecur()"
                    ><el-radio
                      v-for="(item, index) in examSecurityLevelOptions"
                      :key="index"
                      :label="item.value"
                      >{{ item.label }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="form-item-box">
                <el-form-item label="数据发布" prop="dataReleaseList">
                  <el-checkbox-group v-if="ruleForm.securityLevel == 2">
                    <el-checkbox
                      v-for="(item, index) in examReportAutoSendOptions"
                      :key="index"
                      :label="item.value"
                      disabled
                      >{{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                  <el-checkbox-group v-else v-model="ruleForm.dataReleaseList">
                    <el-checkbox
                      v-for="(item, index) in examReportAutoSendOptions"
                      :key="index"
                      :label="item.value"
                      >{{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog
        width="30%"
        title="添加参考学校"
        :visible.sync="innerVisible"
        append-to-body
      >
        <div class="filter">
          <div class="row">
            <div class="label">搜索：</div>
            <el-input
              v-model="search"
              style="width: 260px"
              placeholder="请输入学校名称"
              size="small"
              suffix-icon="el-icon-search"
              @input="searchInput"
            />
          </div>
          <el-button type="text" @click="schoolArr = []">取消选择</el-button>
        </div>
        <div v-loading="schoolLoading">
          <div v-if="schoolList.length" class="schoolCheckBox">
            <el-checkbox-group v-model="schoolArr">
              <el-checkbox
                v-for="(item, index) in schoolList"
                :key="index"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <no-data v-else></no-data>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="sub-btn" @click="cancle">取 消</el-button>
          <el-button class="sub-btn" type="primary" @click="aubmitSchool"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button
          class="sub-btn"
          type="primary"
          :loading="btnLoading"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <copyExam ref="copyExam"></copyExam>
  </div>
</template>

<script>
import {
  examTypeOptions,
  levelOptions,
  gradeHighOptions,
  gradeUniversity,
  gradeMiddleOptions,
  primaryOptions,
  subjectOptions,
  examSecurityLevelOptions,
  examReportAutoSendOptions,
  examStateOptions,
} from "@/core/util/constdata";
import {
  fetchList,
  putObj,
  addObj,
  queryExamClass,
  queryFetchList,
  examnewruleList,
  getSchoolIds,
  getUnionQuerypage,
} from "@/core/api/exam/exam";
const reportList = [
  {
    value: 0,
    label: "未发布考试",
  },
  {
    value: 1,
    label: "已发布给学生的考试",
  },
  {
    value: 2,
    label: "已发布给老师的考试",
  },
  {
    value: 3,
    label: "发布给所有人的考试",
  },
];
import { getSubjectList, getYear } from "@/core/util/util";
import { getStore, setStore, clearStore } from "@/core/util/store";
import {
  schoollistbyexamid,
  getexamprogress,
  examRate,
} from "@/core/api/exam/exam";
import {
  getGradeList,
  getClassListByLevelAndYear,
} from "@/core/api/school/schoolgradeclass";
import ExamItem from "./component/ExamItem.vue";
import copyExam from "@/components/exam/copyExam.vue";

export default {
  name: "ExamNewList",
  components: {
    ExamItem,
    copyExam,
  },
  data() {
    const schoolIdsValidate = (rule, value, callback) => {
      if (this.ruleForm.schoolIdList.length) {
        callback();
      } else {
        callback(new Error("请选择参考学校"));
      }
    };
    return {
      // 列表
      dataList: [],
      // 搜索条件
      searchItems: {
        input: "",
        level: [],
        grade: [],
        year: [],
        type: [],
        status: [],
        subject: [],
        classes: [],
        dataRelease: [],
      },
      search: "", // 参与考试学校搜索条件
      examTypeOptions: examTypeOptions,
      levelOptions: levelOptions(),
      gradeHighOptions: gradeHighOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      gradeUniversity: gradeUniversity,
      primaryOptions: primaryOptions,
      subjectOptions: subjectOptions,
      examSecurityLevelOptions: examSecurityLevelOptions,
      examReportAutoSendOptions: examReportAutoSendOptions,
      reportList: reportList,
      examStateOptions: examStateOptions,
      gradeOptions: [],
      examsubjectOptions: getSubjectList(),
      yearList: getYear(),
      classOptions: [],
      yearMiddleOptions: [], //初中入学年份
      yearHignOptions: [], //高中入学年份
      // 新增 修改考试
      listKey: new Date().getTime(),
      formKeys: new Date().getTime(),
      ruleForm: {
        name: "",
        level: "",
        year: "",
        examDate: "",
        ruleId: 6,
        gradeId: "",
        examSubjectIdList: [],
        examClassIdList: [],
        securityLevel: "", //默认低风险
        dataReleaseList: ["2", "3"], //默认自动发布
        type: "",
        schoolIdList: [],
        examAllow: 0,
      },
      //当前选中的考试规则
      indexRuleData: {},
      btnLoading: false,
      dialogVisible: false,
      innerVisible: false,
      activeName: "exam",
      classOptionsModel: [],
      schooleYearOptions: [],
      rules: {
        name: [{ required: true, message: "请输入考试名称", trigger: "blur" }],
        level: [{ required: true, message: "请选择学段", trigger: "change" }],
        year: [
          { required: true, message: "请选择入学年份", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择考试类型", trigger: "change" },
        ],
        gradeId: [
          { required: true, message: "请选择考试年级", trigger: "change" },
        ],
        ruleId: [
          { required: true, message: "请选择新高考规则", trigger: "change" },
        ],
        securityLevel: [
          { required: true, message: "请选择考试风险等级", trigger: "change" },
        ],
        examDate: [
          {
            required: true,
            message: "请选择考试日期",
            trigger: "change",
          },
        ],
        examSubjectIdList: [
          { required: true, message: "请选择考试科目", trigger: "change" },
        ],
        examClassIdList: [
          { required: true, message: "请选择考试年级", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
        schoolIdList: [
          { required: true, validator: schoolIdsValidate, trigger: "change" },
        ],
        schoolAuth: [
          {
            required: true,
            message: "请选择是否允许学校管理",
            trigger: "change",
          },
        ],
      },
      pageIndex: 1,
      pageSize: 20,
      total: 0,
      dataListLoading: false,
      schoolList: [],
      schoolArr: [],
      schoolIds: [],
      userInfo: {},
      oldSchool: [],
      reportStatusList: [],
      schoolLoading: false,
    };
  },
  watch: {
    "ruleForm.ruleId": {
      deep: true,
      handler() {
        this.indexRuleData = this.examnewruleListData.filter(
          (item) => item.id == this.ruleForm.ruleId
        )[0];
        // console.log(this.indexRuleData);
      },
    },
    "$route.path": {
      handler() {
        this.getDataList();
      },
    },
    pageIndex() {
      this.setParameter();
    },
    pageSize() {
      this.setParameter();
    },
    searchItems: {
      deep: true,
      handler() {
        this.setParameter();
      },
    },
  },

  created() {
    this.init();
    if (this.userInfo.schoolType == 2) {
      this.$router.push({
        name: "/exam/unionExam",
      });
      return;
    }
    // 获取年份
    this.getSchoolYearList();
    // 初始化下拉框数据
    this.initOptions();
    // 获取考试列表
    this.getDataList();
    // 获取新高考规则
    this.examnewruleList();
    //清除vuex中的缓存
    this.$store.commit("delKeepArr");
  },
  destroyed() {
    clearTimeout(this.getReport);
  },
  methods: {
    init() {
      if (getStore({ name: "page_info" })) {
        let page_info = JSON.parse(getStore({ name: "page_info" }));
        this.searchItems = page_info.searchItems;
        this.pageIndex = page_info.pageIndex;
        this.pageSize = page_info.pageSize;
      }
      this.userInfo = JSON.parse(localStorage.getItem("jzjx-userInfo")).content;
    },
    // 点击关闭考试 重新请求考试状态接口
    onloadGetList() {
      if (!this.getReportType) {
        clearTimeout(this.getReport);
        this.getexamprogress();
      }
    },
    // 获取考试结束状态
    getexamprogress() {
      let data = {
        ids: this.dataList.map((item) => item.id).join(","),
      };
      // console.log(this.$route.name);
      if (this.$route.name != "examlist") return;
      this.getReportType = true;
      getexamprogress(data)
        .then((res) => {
          this.getReportType = false;
          this.reportStatusList = res.data.data;
          this.getReport = setTimeout(() => {
            this.getexamprogress();
          }, 3000);
        })
        .catch(() => {
          this.getReportType = false;
        });
    },
    setSecur() {
      // console.log("1111");
      if (this.ruleForm.securityLevel == 1) {
        this.ruleForm.dataReleaseList = ["2", "3"];
      } else {
        this.ruleForm.dataReleaseList = [];
      }
    },
    searchInput() {
      if (this.search) {
        this.schoolList = this.oldSchool.filter(
          (item) => item.name.indexOf(this.search) != -1
        );
      } else {
        this.schoolList = [...this.oldSchool];
      }
    },
    closeTag(index) {
      this.schoolArr.splice(index, 1);
      this.ruleForm.schoolIdList = [...this.schoolArr];
    },
    getSchoolName(id) {
      const data = this.oldSchool.find((item) => item.id == id);
      if (data) {
        return data.name;
      }
    },
    async getSchoolList() {
      this.schoolLoading = true;
      const res = await getSchoolIds({ type: 1, id: this.userInfo.schoolId });
      this.schoolList = res.data.data;
      this.oldSchool = res.data.data;
      this.schoolLoading = false;
    },
    addSchool() {
      this.innerVisible = true;
      this.schoolArr = [...this.ruleForm.schoolIdList];
      this.getSchoolList();
    },
    cancle() {
      this.innerVisible = false;
      this.schoolArr = [];
      this.search = "";
    },
    aubmitSchool() {
      this.innerVisible = false;
      this.ruleForm.schoolIdList = [...this.schoolArr];
      this.search = "";
    },
    setParameter() {
      let data = {
        searchItems: this.searchItems,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      let JSONData = JSON.stringify(data);
      // console.log(JSONData);
      setStore({ name: "page_info", content: JSONData, type: 1 });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;

      this.getDataList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;

      this.getDataList();
      // console.log(`当前页: ${val}`);
    },
    setList() {
      this.getDataList();
    },
    // 获取新高考规则
    examnewruleList() {
      examnewruleList().then((res) => {
        // let array=[
        //  { id:"5"}
        // <div>{{ v.rankName }}</div>
        // <div>{{ v.rankNum }}</div>
        // <div>{{ v.rankScore }}</div>
        // ]
        this.examnewruleListData = res.data.data.filter((item) => item.id == 6);
        this.examnewruleListData[0].ruleRankList = [
          {
            rankName: "A",
            rankNum: "15%",
            rankScore: "100~86",
          },
          {
            rankName: "B",
            rankNum: "35%",
            rankScore: "85~71",
          },
          {
            rankName: "C",
            rankNum: "35%",
            rankScore: "70~56",
          },
          {
            rankName: "D",
            rankNum: "13%",
            rankScore: "55~41",
          },
          {
            rankName: "E",
            rankNum: "2%",
            rankScore: "40~30",
          },
        ];
      });
    },
    getDis() {
      let type = false;
      if (this.ruleForm.examClassIdList.length > 0 && this.ruleForm.id) {
        type = true;
      }
      return type;
    },
    copyList() {
      this.$refs.copyExam.init();
    },
    // 新增 修改考试 弹出
    async editList(val, type) {
      if (type) {
        this.classOptionsModel = [];
        //
        Object.assign(this.$data.ruleForm, this.$options.data().ruleForm);
        // this.ruleForm.examDate = "";
        // this.ruleForm =this.$option.ruleForm
        this.ruleForm.id = 0;
        this.formKeys = new Date().getTime();
      } else {
        let examClassIdList = [];
        if (val.classIds && val.classIds != "null") {
          examClassIdList = val.classIds.split(",");
        }
        this.ruleForm = {
          examClassIdList: examClassIdList,
          examSubjectIdList: (val.subjectIds || "").split(",").map(Number),
          dataReleaseList: (val.dataRelease || "")
            .split(",")
            .filter((item) => item != "null"),
          gradeId: val.gradeId,
          level: val.level.toString(),
          name: val.name,
          year: val.year.toString(),
          type: val.type,
          securityLevel: val.securityLevel,
          examDate: val.examDate,
          id: val.id,
          ruleId: val.ruleId,
        };

        if (val.examType == 1) {
          this.getSchoolList();
          const res = await schoollistbyexamid({ examId: val.id });
          const schoolIdList = res.data.data.map((item) => item.schoolId);
          this.schoolArr = [...schoolIdList];
          this.$set(this.ruleForm, "schoolIdList", [...schoolIdList]);
          this.$set(this.ruleForm, "examAllow", val.examAllow);
        }
      }
      if (val.ruleId) {
        this.activeName = "newExam";
      } else {
        this.activeName = "exam";
      }

      if (this.ruleForm.level == 2) {
        //初中政治改为道德与法治
        this.subjectOptions = getSubjectList(2);
      }

      if (this.ruleForm.level == 3) {
        //初中政治改为道德与法治
        this.subjectOptions = getSubjectList(3);
      }
      if (this.ruleForm.level == 1) {
        //初中政治改为道德与法治
        this.subjectOptions = getSubjectList(1);
      }

      // console.log(this.ruleForm);
      this.changeGradeOptions();
      //  console.log(this.gradeOptions);
      this.dialogVisible = true;
    },
    // 与 Exam.vue 方法相同，两处地方都要改
    // 修改学段，需要修改年级以及班级
    changeGradeOptions(changeGradeOrClass) {
      if (changeGradeOrClass) {
        this.ruleForm.gradeId = "";
        // this.ruleForm.year = "";
        this.classOptionsModel = [];
      }
      // this.ruleForm.examSubjectIdList = [];
      if (this.ruleForm.level == 2) {
        this.gradeOptions = gradeMiddleOptions;
        this.schooleYearOptions = this.yearMiddleOptions;

        //初中政治改为道德与法治

        this.subjectOptions = getSubjectList(2);
      }
      if (this.ruleForm.level == 1) {
        this.subjectOptions = getSubjectList(1);
        this.gradeOptions = primaryOptions;
        this.schooleYearOptions = this.yearHignOptions;
      }
      if (this.ruleForm.level == 3) {
        this.subjectOptions = getSubjectList(3);
        this.classOptionsModel = [];
        this.schooleYearOptions = [];
        this.gradeOptions = gradeHighOptions;
        this.schooleYearOptions = this.yearHignOptions;
      }
      if (this.ruleForm.level == 4) {
        this.subjectOptions = getSubjectList(4);
        this.classOptionsModel = [];
        this.schooleYearOptions = [];
        this.gradeOptions = gradeUniversity;
        this.schooleYearOptions = this.yearHignOptions;
      }

      this.changeClassOptions(changeGradeOrClass);
    },
    changeMode() {
      this.pageIndex = 1;
      this.getDataList();
    },
    // 与 Exam.vue 方法相同，两处地方都要改
    // 修改入学年份，根据学段和入学年份查询班级
    changeClassOptions(changeGradeOrClass) {
      if (changeGradeOrClass) {
        this.ruleForm.examClassIdList = [];
      }
      if (this.ruleForm.level && this.ruleForm.year) {
        let query = { level: this.ruleForm.level, year: this.ruleForm.year };
        getClassListByLevelAndYear(query).then((response) => {
          this.classOptionsModel = response.data.data;
          // console.log(this.classOptions);
        });
      }
    },
    // 与 Exam.vue 方法相同，两处地方都要改
    // 获取所有年级列表
    getSchoolYearList() {
      getGradeList().then((response) => {
        let grades = response.data.data;
        if (grades && grades.length > 0) {
          let arr = [];
          grades.forEach((item) => {
            if (arr.indexOf(item.year) == -1) {
              arr.push(item.year);

              // this.yearList.map(index=>{

              // })
            }

            if (item.level === 2) {
              this.yearMiddleOptions.push({
                value: item.year,
                label: item.year,
              });
            } else {
              this.yearHignOptions.push({ value: item.year, label: item.year });
            }
          });
          let yearMiddleOptions;
          yearMiddleOptions = this.yearMiddleOptions.filter(
            (currentValue, currentIndex, selfArr) => {
              return (
                selfArr.findIndex((x) => x.value === currentValue.value) ===
                currentIndex
              );
            }
          );
          this.yearMiddleOptions = yearMiddleOptions;
          let yearHignOptions;
          yearHignOptions = this.yearHignOptions.filter(
            (currentValue, currentIndex, selfArr) => {
              return (
                selfArr.findIndex((x) => x.value === currentValue.value) ===
                currentIndex
              );
            }
          );
          this.yearHignOptions = yearHignOptions;
          arr.sort();
          arr.map(() => {
            // this.yearList.push({
            //   value: index,
            //   label: index,
            //   // level: item.level
            // });
          });
        }
      });
    },
    typeClick() {
      this.$refs.ruleForm.resetFields();
      if (this.activeName == "newExam") {
        this.ruleForm.level = "3";
        this.ruleForm.examSubjectIdList = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        this.changeGradeOptions();
      }
      // let { schoolPeriod } = getStore({ name: "userInfo" });
      // console.log(schoolPeriod);
    },
    getshowNewExam() {
      let type = false;
      if (!this.ruleForm.id || (this.ruleForm.id && this.ruleForm.ruleId > 0)) {
        type = true;
      }
      let { schoolPeriod } = getStore({ name: "userInfo" });
      // console.log(schoolPeriod);
      if (schoolPeriod.indexOf("3") == -1) {
        type = false;
      }
      // console.log(type);
      return type;
    },
    // 保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.level == 2 || this.ruleForm.level == 1) {
            this.ruleForm.examSubjectIdList =
              this.ruleForm.examSubjectIdList.filter((item) => item != 6);
          }
          if (this.ruleForm.level == 3) {
            this.ruleForm.examSubjectIdList =
              this.ruleForm.examSubjectIdList.filter((item) => item != 11);
          }
          if (this.userInfo.roleId == 6) {
            // 联考
            this.$set(this.ruleForm, "examType", 1);
          } else {
            // 普通考试
            this.$set(this.ruleForm, "examType", 0);
          }
          //新高考
          if (this.activeName == "newExam") {
            this.ruleForm.examClassIdList = [];
          } else {
            this.ruleForm.ruleId = "";
          }
          this.ruleForm.dataRelease = this.ruleForm.dataReleaseList.join(",");

          // console.log(this.ruleForm);
          // if (this.ruleForm) {
          //   return;
          // }
          this.btnLoading = true;
          if (this.ruleForm.id) {
            putObj(this.ruleForm)
              .then(() => {
                this.$message.success("修改成功");
                this.dialogVisible = false;
                // let time = new Date().getDate();
                this.getDataList();
                this.btnLoading = false;
              })
              .catch(() => {
                // this.dialogVisible = false;
                this.btnLoading = false;
              });
          } else {
            addObj(this.ruleForm)
              .then(() => {
                this.$message.success("添加成功");
                // let time = new Date().getDate();
                // 恢复搜索条件
                clearStore({ type: 1 });

                this.pageIndex = 1;
                Object.assign(
                  this.$data.searchItems,
                  this.$options.data().searchItems
                );
                // this.searchItems.map
                this.getDataList();
                this.dialogVisible = false;
                this.btnLoading = false;
                // this.$refs.ruleForm.resetFields();
              })
              .catch(() => {
                this.btnLoading = false;
                // this.dialogVisible = false;
              });
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    initOptions() {
      // 初始化学段选项
      let { schoolPeriod } = getStore({ name: "userInfo" });
      // this.roleId = roleId;
      // let schoolPeriod = userInfo.schoolPeriod;
      if (!schoolPeriod) {
        schoolPeriod = "";
      }
      let periodArr = schoolPeriod.split(",");
      for (let li = 0; li < this.levelOptions.length; li++) {
        let option = this.levelOptions[li];
        let found = false;
        for (let pi = 0; pi < periodArr.length; pi++) {
          if (option.value == periodArr[pi]) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.levelOptions.splice(li, 1);
          li--;
        }
      }
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;

      // if (this.searchItems) {
      //   let type = false;
      //   // 遍历key判断是否有值
      //   for (let i in this.searchItems) {
      //     if (this.searchItems[i] && this.searchItems[i].length) {
      //       type = true;
      //     }
      //   }
      //   // console.log(type);

      //   if (type) {
      // if (this.$route.path == "/exam/unionExam") {
      //   this.getUnionQuerypageList();
      // } else {
      this.queryFetchList();
      // }
      //   } else {
      //     this.normalFetchList();
      //   }
      // } else {
      //   this.normalFetchList();
      // }
    },
    async getUnionQuerypageList() {
      const res = await getUnionQuerypage(
        Object.assign(
          {
            current: this.pageIndex,
            size: this.pageSize,
            schoolId: this.userInfo.schoolId,
          },
          this.searchItems
        )
      );

      this.setDataList(res);
    },
    //非搜索请求的接口
    normalFetchList() {
      fetchList({
        current: this.pageIndex,
        size: this.pageSize,
      })
        .then((response) => {
          this.setDataList(response);
        })
        .catch(() => {
          this.dataListLoading = false;
        });
    },
    // 搜索请求的接口
    queryFetchList() {
      queryFetchList(
        Object.assign(
          {
            current: this.pageIndex,
            size: this.pageSize,
          },
          this.searchItems
        )
      )
        .then((response) => {
          this.setDataList(response);
        })
        .catch(() => {
          // this.dataList = [];
          this.dataListLoading = false;
        });
    },
    // 设置需要显示的列表数据
    setDataList(response) {
      // console.log(response.data.data.records);
      this.dataListLoading = false;
      let ids = [];
      let itemKey = [];
      response.data.data.records.forEach((item) => {
        item.showPaperList = true;
        item.showTable = false;
        if (!item.paperList) {
          return;
        }
        // console.log();
        if (item.status == 1) {
          item.paperList.map((index) => {
            ids.push(index.paperId);
          });
          itemKey.push("ExamItem" + item.id);
        }
      });
      let data = { ids: ids.join(",") };
      this.total = response.data.data.total;
      this.dataList = response.data.data.records;
      this.listKey = new Date().getTime();
      this.$nextTick(() => {
        if (!this.$refs["ExamItem" + this.$route.query.examId]) return;
        let indexElement = this.$refs["ExamItem" + this.$route.query.examId][0];
        document.documentElement.scrollTop = indexElement.$el.offsetTop - 80;
      });

      if (data.ids.length == 0) {
        return;
      }
      this.examRateTime(data, itemKey, this.listKey);

      clearTimeout(this.getReport);
      this.getexamprogress();
    },
    examRateTime(data, itemKey, time) {
      examRate(data).then((res) => {
        if (time != this.listKey) return;
        this.examRate = res.data.data.map((item) => {
          return {
            paperId: item.paperId,
            rate: item.rate,
          };
        });
        setTimeout(() => {
          this.$nextTick(() => {
            itemKey.map((item) => {
              this.$refs[item][0].initRate(this.examRate);
            });
          });
        }, 400);
      });
    },
    // /全选，反选，取消
    examClassIdListTo(val) {
      if (val == 1) {
        this.ruleForm.examClassIdList = [];
        this.classOptionsModel.forEach((i) => {
          this.ruleForm.examClassIdList.push(i.id.toString());
        });
      }
      if (val == 2) {
        let arr = [];
        this.classOptionsModel.forEach((i) => {
          if (this.ruleForm.examClassIdList.indexOf(i.id.toString()) == -1) {
            arr.push(i.id.toString());
          }
        });
        this.ruleForm.examClassIdList = arr;
      }
      if (val == 3) {
        this.ruleForm.examClassIdList = [];
      }
    },
    handleClick() {
      this.pageIndex = 1;
      this.getDataList();
    },

    // 修改年级
    changeGrade() {
      this.pageIndex = 1;
      this.searchItems.classes = [];
      this.classOptions = [];
      this.refreshClassList();
      this.getDataList();
    },
    // 刷新班级列表
    refreshClassList() {
      // 班级搜索字符串 格式： level_year,level_year
      let classStr = [];
      this.searchItems.level.forEach((index) => {
        this.searchItems.year.forEach((item) => {
          // console.log(index + "_" + item);
          classStr.push(index + "_" + item);
        });
      });

      if (classStr.length > 0) {
        classStr = classStr.join(",");
        // classStr = classStr.substring(1);
      } else {
        return;
      }
      let data = new URLSearchParams();
      data.append("classStr", classStr);
      queryExamClass(data).then((response) => {
        this.classOptions = [];
        let classes = response.data.data;
        let array = [];
        classes.forEach((classObj) => {
          if (array.indexOf(classObj.name) == -1) {
            this.classOptions.push({
              value: String(classObj.id),
              label: classObj.name,
            });
            array.push(classObj.name);
          }
        });
        // console.log(this.classOptions);
      });
    },
    // 修改学段
    changeLevel() {
      this.searchItems.grade = [];
      this.searchItems.classes = [];
      this.examYearOptions = [];
      this.classOptions = [];
      this.pageIndex = 1;
      // this.searchItems.year = [];
      // console.log(this.yearHignOptions);
      // level.forEach((val) => {
      //   if (val === "2") {
      //     this.examYearOptions = this.examYearOptions.concat(
      //       this.yearHignOptions
      //     );
      //   } else if (val === "3") {
      //     this.examYearOptions = this.examYearOptions.concat(
      //       this.yearMiddleOptions
      //     );
      //   }
      // });
      this.ruleForm.examSubjectIdList = [];
      // examsubjectOptions
      // 初中：道德与法治--高中:政治
      this.searchItems.subject.forEach((item, key) => {
        if (item == 6) {
          this.searchItems.subject.splice(key, 1);
        }
      });
      // let levelId = this.searchItems.level.join("");

      // if (levelId == 2) {
      this.examsubjectOptions = getSubjectList();
      // } else if (levelId == 3) {
      //   this.examsubjectOptions = getSubjectList(3);
      // } else {
      //   this.examsubjectOptions = getSubjectList(1);
      // }
      this.refreshClassList();
      this.getDataList();
    },
  },
};
</script>
<style lang="scss">
.popper-box {
  background-color: #fff5db;
  .popper__arrow {
    &::after {
      border-right-color: #fff5db !important;
      border-left-color: #fff5db !important;
    }
  }
}
.rule-box {
  > div {
    margin-bottom: 8px;
  }
  .role-list-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    > p {
      width: 100%;
    }
    // margin-right: 12px;
    .role-item {
      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      width: 395px;
    }
    .role-list {
      width: 80px;
      display: flex;
      flex-wrap: wrap;
      // margin-right: 12px;
      border: 1px solid #dddddd;
      border-right: 0;
      margin-bottom: 8px;
      &:last-child {
        border-right: 1px solid #dddddd;
      }
      div {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #dddddd;
        background-color: #fffdf7;
        line-height: 27px;

        &:last-child {
          border: none;
        }
      }
    }
  }
}
.ExamNewList {
  .role-btn {
    margin-left: 12px;
    cursor: pointer;
    i {
      color: #f7be65;
    }
  }
  .role-list-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-right: 12px;
    .role-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 395px;
    }
    .role-list {
      width: 80px;
      display: flex;
      flex-wrap: wrap;
      margin-right: 12px;
      border: 1px solid #dddddd;
      margin-bottom: 8px;
      div {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #dddddd;
        line-height: 27px;
        background-color: #ffffff;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .el-dialog {
    max-width: 816px;
    .el-dialog__header {
      // padding: 24px 32px 0;
      // .el-dialog__title {
      //   font-size: 20px;
      //   font-weight: 600;
      // }
      // .el-dialog__headerbtn {
      //   right: 29px;
      //   top: 29px;
      // }
    }
    .el-dialog__body {
      padding: 24px 32px;
      .el-form {
        &:last-child {
          // border-bottom: 1px solid #e0e0e0;
        }
      }

      .form-item-box {
        // border-top: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .checkbox-item {
          width: 100%;
          // padding-left: 100px;
          margin-bottom: 8px;
        }
        .el-date-editor.el-input {
          width: 100%;
        }
        .item-box-btn {
          display: flex;
          justify-content: space-between;
          p {
            color: &primary-color;
            font-size: 14px;
            margin-right: 8px;
            margin-left: 8px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .el-select {
          width: 100%;
        }
        .el-form-item {
          width: 100%;
        }
      }
      .two-box {
        .el-form-item {
          width: 49%;
        }
      }
      .el-form-item {
        padding: 8px 0;
        margin-bottom: 0;
      }
    }
    .el-form-item__label {
      text-align: left;
    }
    // .el-input {
    //   input {
    //     background-color: #f5f5f5;
    //     border-color: #f5f5f5;
    //   }
    // }

    // .el-form-item.is-required:not(.is-no-asterisk)
    //   .el-form-item__label-wrap
    //   > .el-form-item__label:before,
    // .el-form-item.is-required:not(.is-no-asterisk)
    //   > .el-form-item__label:before {
    //   content: "";
    // }
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #f5f5f5;
  }
  .el-select .el-input__inner:focus {
    border-color: #f5f5f5;
  }
  .el-select .el-input__inner:hover {
    border-color: #f5f5f5;
  }
  .el-icon-arrow-up:before {
    content: "\e78f";
  }
  .el-select .el-input .el-select__caret {
    color: rgba(0, 0, 0, 0.5);
  }
}
.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  .row {
    display: flex;
    align-items: center;
    .label {
      font-size: 14px;
      color: #101011;
    }
  }
}
.schoolCheckBox {
  width: 100%;
  .el-checkbox-group {
    width: 100%;
    .el-checkbox {
      width: 33% !important;
      margin: 0 !important;
      margin-bottom: 32px !important;
    }
  }
}
.tags {
  margin-top: 20px;
  .el-tag {
    margin-right: 24px;
    margin-bottom: 24px;
  }
}
</style>
<style lang="scss" scoped>
.ExamNewList {
  padding: 24px 0 60px;
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 60px;
  }
  .exam-question-list-box {
    padding: 24px;
    background-color: #ffffff;
    margin-top: 24px;
  }
  .exam-header {
    padding: 24px 24px 0;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add-exam-btn {
      margin-bottom: 24px;
      flex-shrink: 0;
    }
    .exam-left-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      i {
        cursor: pointer;
      }
      .search-input {
        width: 192px;
      }
      > div {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 24px;
      }
    }
    .el-select {
      width: 112px;
      margin-right: 24px;
      ::v-deep .el-input--suffix {
        .el-input__inner {
          max-height: 32px;
        }
      }
      ::v-deep .el-tag.el-tag--info {
        max-width: 70px;
      }
      ::v-deep .el-select__tags {
        max-height: 28px;
        > span {
          display: flex;
          max-height: 32px;
        }
      }
    }
  }
}
</style>
